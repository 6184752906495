import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Fade } from "react-reveal";
import Pulse from "react-reveal/Pulse";
import useOnScreen from "../../components/atoms/appearance/Appearanc";
import config from "react-reveal/globals";

const HalfImageDescription = ({
  lessContent,
  heading,
  paragraph,
  image,
  imageAltText,
  reverserImage,
  extraImageStyles,
  grayBackground = false,
  extraContentStyles,
  extraImageClasses,
  extraImageDivClasses,
  extraContentClasses,
}) => {
  const ref = useRef();
  // const currentLanguage = useLanguage();
  const isVisible = useOnScreen(ref);
  useEffect(() => {
    console.log(isVisible);
    config({ ssrFadeout: isVisible });
  }, [isVisible]);
  return (
    <div
      ref={ref}
      style={{ background: `${grayBackground ? "#f2f2f2" : "white"}` }}
      className={`section section-br half-content-wrapper  ${
        lessContent ? " less-content-wrapper" : " more-content-wrapper "
      }`}
    >
      <Fade duration={1000} opposite>
        {reverserImage ? (
          <div className="inner-wrapper reverse-inner-wrapper">
            <div
              className={`half-page-image-wrapper reverse-image ${extraImageDivClasses}`}
            >
              <Fade duration={1000}>
                <div className="image-content-wrapper">
                  <img
                    className={`${extraImageClasses ? extraImageClasses : ""}`}
                    style={extraImageStyles ? extraImageStyles : {}}
                    src={image}
                    alt={imageAltText ? imageAltText : "Goken America"}
                  />
                </div>
              </Fade>
            </div>
            <div
              className={`half-page-info-wrapper reverse-info ${extraContentClasses}`}
              style={extraContentStyles ? extraContentStyles : {}}
            >
              <Fade right>
                <h2 className="heading">{heading}</h2>
                <p className="para">{paragraph}</p>
              </Fade>
            </div>
          </div>
        ) : (
          <div className="inner-wrapper">
            <div
              className={`half-page-info-wrapper reverse-info ${extraContentClasses}`}
            >
              <Fade left>
                <h2 className="heading">{heading}</h2>
                <p className="para">{paragraph}</p>
              </Fade>
            </div>
            <div className="half-page-image-wrapper">
              <Fade duration={1000}>
                <div className="image-content-wrapper">
                  <img
                    className={`${extraImageClasses ? extraImageClasses : ""}`}
                    style={extraImageStyles ? extraImageStyles : {}}
                    src={image}
                    alt={imageAltText ? imageAltText : "Goken America"}
                  />
                </div>
              </Fade>
            </div>
          </div>
        )}
      </Fade>
    </div>
  );
};
HalfImageDescription.defaultProps = {
  imapgeAltText: ``,
  //   meta: [],
  //   description: ``,
};

HalfImageDescription.propTypes = {
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};
export default HalfImageDescription;
